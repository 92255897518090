import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SuccessPage = () => {
  return (
    <Layout>
      <SEO title="Potwierdzenie wysłania wiadomości" />
      <main className="contact_page">
        <div className="container-fluid">
          <div className="page_header_wrapper">
            <h1 className="page_header" data-text="&#60;Kont4kt/&#62;">
              &#60;Kont<span>4</span>kt/&#62;
            </h1>
            <div className="text-center">
              <p className="text-success">Dziękujemy za wysłanie wiadomości</p>
              <br />
              <Link to="/" className="btn_custom">
                Powrót do strony głównej
              </Link>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default SuccessPage
